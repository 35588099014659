.container {
    width           : calc(300px);
    height          : calc(100vh);
    padding         : 10px 15px;
    background-color: white;
    position        : relative;
    padding         : 20px;
    left            : 0;
    top             : 0;
    position        : fixed;
    box-shadow      : 3px 0 20px rgba(0, 0, 0, 0.03);
    z-index         : 1;
}

.button {
    padding        : 20px 20px;
    border-radius  : 10px;
    display        : flex;
    align-items    : center;
    font-weight    : 700;
    cursor         : pointer;
    transition     : 0.2s ease all;
    color          : inherit;
    text-decoration: none;
    margin-bottom  : 10px;

    &:hover,
    &.current {
        background-color: var(--light-grey-color);
        color           : inherit;
    }

    .badge {
        width           : 24px;
        height          : 24px;
        background-color: var(--green-color);
        border-radius   : 100%;
        display         : flex;
        align-items     : center;
        justify-content : center;
        text-align      : center;
        position        : absolute;
        right           : 30px;
        color           : white;
        font-size       : 12px;
    }
}


.logoutButton {
    top  : 10px;
    right: 20px;
}