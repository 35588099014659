@keyframes ldio-s02vtdvpfla {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.ldio div {
    position        : absolute;
    width           : 20px;
    height          : 20px;
    border          : 3px solid #ffffff;
    border-top-color: transparent;
    border-radius   : 50%;
}

.ldio div {
    animation: ldio-s02vtdvpfla 1s linear infinite;
    top      : 15px;
    left     : 15px
}

.spinnerRolling {
    width   : 30px;
    height  : 30px;
    display : inline-block;
    overflow: hidden;
    position: absolute;
    right   : 10px;
}

.ldio {
    width              : 100%;
    height             : 100%;
    position           : relative;
    transform          : translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin   : 0 0;
    /* see note above */
}

.ldio div {
    box-sizing: content-box;
}

.button {
    position: relative;

    &.loading {
        padding-right : 50px;
        pointer-events: none;
    }
}

/* generated by https://loading.io/ */