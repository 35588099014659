.container {

    position: relative;
    width   : 40px;

    .button {
        width              : 40px;
        height             : 40px;
        display            : inline-block;
        background-image   : url('../../assets/icons/menu_bullets.png');
        background-size    : 60%;
        background-repeat  : no-repeat;
        background-position: center center;
        border-radius      : 100%;
        background-color   : transparent;
        padding            : 20px;
        cursor             : pointer;
        transition         : 0.2s ease all;
    }

    &:hover {

        .button {
            background-color          : #EDEFF0;
            border-bottom-left-radius : 0;
            border-bottom-right-radius: 0;
        }

        .content {
            display: inline-block;
            z-index: 9;
        }
    }

    .content {
        display            : inline-block;
        display                : none;
        background-color       : #EDEFF0;
        position               : absolute;
        top                    : 35px;
        right                  : 0;
        padding                : 3px 20px;
        border-radius          : 15px;
        border-top-right-radius: 0;
        width                  : max-content;


        &.show {
            display: inline-block;
        }
    }

    .item {
        width          : 100%;
        display        : block;
        text-decoration: underline;
        color          : var(--green-color);
        cursor         : pointer;
        padding        : 10px 0;
    }

}